<template>
  <div class="main-wrapper layout-default" :class="{ gray: isGray, darkgray: isDarkGray }">
    <main>
      <RouterView>
        <slot />
      </RouterView>
    </main>

    <!--Absolute positioned blocks-->
    <PWASuggestion />
    <NewVersion />
  </div>
</template>

<script>
  import { isPrimaryView, isSecondaryView } from '@/components/blocks/layouts/dual/SecondaryView';
  import PWASuggestion from 'components/blocks/layouts/PWASuggestion';
  import NewVersion from 'components/blocks/layouts/NewVersion';
  import { persistentStorage, PersistentStorageKeys } from 'utils/persistentStorage';

  export default {
    name: 'DefaultLayout',

    components: { NewVersion, PWASuggestion },

    provide: {
      [isSecondaryView]: false,
      [isPrimaryView]: true,
    },

    computed: {
      isGray() {
        return this.$route.meta.colorBg === 'gray';
      },
      isDarkGray() {
        return this.$route.meta.colorBg === 'dark-gray';
      },
    },

    watch: {
      $route() {
        persistentStorage.set(
          PersistentStorageKeys.LAST_LOCATION,
          `${window.location.pathname}${window.location.hash}`,
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layout-default {
    width: 100%;
    height: calc(100vh - 60px);
    overflow: auto;

    @media print {
      height: unset;
    }
  }
</style>
