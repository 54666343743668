<template>
  <div v-if="isShow" class="pwa-suggestion" @click="hide">
    You can install this web application on your apple device! <br />
    Tap <IconMaterial title="ios_share" class="pwa-suggestion__icon-share" /> and then "<span
      class="pwa-suggestion__label-add-to-home-screen"
      >Add to homescreen</span
    >"
    <div class="pwa-suggestion__arrow"></div>
  </div>
</template>

<script>
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import NavigatorHelper, { OperatingSystems } from 'utils/NavigatorHelper.ts';
  import { differenceInDays } from 'date-fns';
  import { persistentStorage, PersistentStorageKeys } from 'utils/persistentStorage';

  const MS_QUARTER_OF_MINUTE = 15000;
  const DAYS_IN_MONTH = 30;

  export default {
    name: 'PWASuggestion',

    components: { IconMaterial },

    data: () => ({
      isShow: false,
    }),

    computed: {
      wasShownPopupLast30Days() {
        const wasSuggestedPWAInstallation = persistentStorage.get(
          PersistentStorageKeys.WAS_SUGGESTED_PWA_INSTALLATION,
        );

        if (wasSuggestedPWAInstallation) {
          const showLastTime =
            wasSuggestedPWAInstallation && JSON.parse(wasSuggestedPWAInstallation)?.when;

          return differenceInDays(new Date(), new Date(showLastTime)) <= DAYS_IN_MONTH;
        }

        return false;
      },
    },

    created() {
      this.isShow =
        !this.wasShownPopupLast30Days &&
        !NavigatorHelper.isStandaloneApplication &&
        NavigatorHelper.OS === OperatingSystems.IOS;

      if (this.isShow) {
        persistentStorage.set(
          PersistentStorageKeys.WAS_SUGGESTED_PWA_INSTALLATION,
          JSON.stringify({
            when: new Date(),
          }),
        );

        setTimeout(() => {
          this.isShow = false;
        }, MS_QUARTER_OF_MINUTE);
      }
    },

    methods: {
      hide() {
        this.isShow = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .pwa-suggestion {
    position: fixed;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    border-radius: $border-radius__lg;
    background-color: $color-bg-third;
    text-align: center;
    line-height: 1.5;
    box-shadow: 0px -5px 9px -2px #7474745c;
    min-width: 300px;
    width: max-content;

    @media (max-width: $screen-xxs-max) {
      font-size: $size-xxs;
    }

    &__icon-share,
    &__label-add-to-home-screen {
      color: $color-text-primary;
    }

    &__icon-share {
      position: relative;
      top: 2px;
    }

    &__label-add-to-home-screen {
      font-weight: $weight-bold;
    }

    &__arrow {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: $color-bg-third transparent transparent transparent;
    }
  }
</style>
